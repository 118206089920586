import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client';

let uri = `${window.location.protocol}//${window.location.host}:4000/`;
if (process.env.NODE_ENV === 'production') {
} else if (process.env.NODE_ENV === 'development') {
   uri = 'http://localhost:4040/'
}

export const tokenManager = {
    token: null,
    permission: {
      remotePermission: false
    }
}

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: tokenManager.token || '',
      }
    });
  
    return forward(operation);
})

const client = new ApolloClient({
    cache: new InMemoryCache(),
    credentials: 'include',
    link: authMiddleware.concat(new HttpLink({uri, credentials: 'include'})),
});

export default client;