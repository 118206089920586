import React from "react";
import { useMutation } from "react-apollo";
import { SIGN_UP } from "../queries";
import {
  Form,
} from "react-bootstrap";

const Signup = () => {
  const [signup, { data }] = useMutation(SIGN_UP);
  async function signupRequest(e) {
    e.preventDefault();
    let id = e.target.elements.username.value;
    let pw = e.target.elements.pwd.value;
    let pw2 = e.target.elements.pwd2.value;
    if (pw != pw2) {
      alert("비밀번호 확인이 일치하지 않습니다.");
      return;
    }
    let res = await signup({ variables: { id, pw } });
    if (!res.data.register.success) {
      alert(res.data.register.message);
    } else {
      alert('가입 성공');
      window.location.href = "/auth/login";
    }
  }
  return (
    <div className="text-center">
      <Form onSubmit={d=>signupRequest(d)}>
        <h3>회원가입</h3>
        <div className="form-group">
          <label>아이디</label>
          <input
            name="username"
            className="form-control"
            placeholder="아이디 입력"
          />
        </div>

        <div className="form-group">
          <label>비밀번호</label>
          <input
            name="pwd"
            type="password"
            className="form-control"
            placeholder="비밀번호 입력"
          />
        </div>

        <div className="form-group">
          <label>비밀번호 확인</label>
          <input
            name="pwd2"
            type="password"
            className="form-control"
            placeholder="비밀번호 입력"
          />
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          확인
        </button>
      </Form>
    </div>
  );
};

export default Signup;
