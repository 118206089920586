import React from "react";
import { useMutation } from "react-apollo";
import { LOGIN } from "../queries";
import {
  Form,
} from "react-bootstrap";
import { tokenManager } from "../apolloClient";
import { useHistory } from "react-router-dom";

const Login = ({refetch}) => {
  let history = useHistory();

  const [login] = useMutation(LOGIN);
  async function loginRequest(e) {
    e.preventDefault();
    let res = await login({
      variables: {
        id: e.target.elements.username.value,
        pw: e.target.elements.pwd.value,
      },
    });
    if (!res.data.login) {
      alert("잘못된 계정 정보입니다.");
    } else {
      tokenManager.token = res.data.login.token;
      tokenManager.permission = res.data.login.permission;
      refetch();
      history.push('/home')
    };
  }
  return (
    <>
      <div className="text-center">
        <Form onSubmit={(d) => loginRequest(d)}>
          <h3>로그인</h3>
          <div className="form-group">
            <label>아이디</label>
            <input
              name="username"
              className="form-control"
              placeholder="아이디 입력"
            />
          </div>

          <div className="form-group">
            <label>비밀번호</label>
            <input
              name="pwd"
              type="password"
              className="form-control"
              placeholder="비밀번호 입력"
            />
          </div>

          <button type="submit" className="btn btn-primary btn-block">
            확인
          </button>
        </Form>
      </div>
      <a href="/auth/signup" className="float-right pt-1">
        회원가입
      </a>
    </>
  );
};

export default Login;
