import React, { useState } from "react";
import "./RoomList.css";
import { UPDATE_CONFIG } from "../queries";
import { useMutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import "./ServiceContainer.css";
import {
  InputGroup,
  FormControl,
  Form,
  Button,
  Card,
  Accordion,
} from "react-bootstrap";

const dictionary = {
  chatKiller: "메세지 설정",
  enabled: "상태",
  kickSuspiciousUser: "매우 의심스러운 계정 자동강퇴",
  defaultChat: "메세지 필터",
  firstChat: "첫 메세지 필터",
  hideFilter: "가리기 필터",
  kickFilter: "가리기+강퇴 필터",
  filterList: "필터 목록",
  lengthFilter: "메세지 길이 초과 강퇴",
  filterLength: "길이 (10~1000)",
  firstRange: "첫 채팅 범위 (1~10)",
  defaultUser: "일반 계정",
  suspiciousUser: "의심스러운 계정",
  string: "문자열",
  regex: "정규식",
  permanentKick: "영구 강퇴",
  photoFilter: '사진',
  kakaoLinkFilter: '카카오링크',
  sharpSearchFilter: '# 검색',
  scheduleFilter: '일정',
  mentionFilter: '다중 멘션',
  typeFilter: '유형별 설정',
  openPostFilter: '공지',
  voteFilter: '투표',
  profileFilter: '프로필',
  timeLimit: '~초 이내에',
  countLimit: '메세지 ~개 이상',
  spamFilter: '스팸 설정',
  hidePreviousIfKick: '강퇴시 이전 메세지 모두 가리기',
  kickForeignAccount: '해외계정 입장시 강퇴'
};

const ServiceContainer = ({
  data,
  match: {
    params: { uid },
  },
}) => {
  const [dataState, setData] = useState(data);
  const [updateConfig] = useMutation(UPDATE_CONFIG);

  function deleteItem(original, i) {
    original.splice(i, 1);
    setData(JSON.parse(JSON.stringify(data)));
    save();
  }

  function modify(stack, e, data) {
    e.preventDefault();
    let value = e.target.elements.content.value;
    if (stack[0][0] === "timeLimit") {
      if (!/^[1-9][0-9]*$/.test(value) || value > 10 || value < 1) {
        e.target.elements.content.value = "";
        return;
      }
    }
    if (stack[0][0] === "countLimit") {
      if (!/^[1-9][0-9]*$/.test(value) || value > 10 || value < 1) {
        e.target.elements.content.value = "";
        return;
      }
    }
    if (stack[0][0] === "filterLength") {
      if (!/^[1-9][0-9]*$/.test(value) || value > 1000 || value < 10) {
        e.target.elements.content.value = "";
        return;
      }
    }
    if (stack[0][0] === "firstRange") {
      if (!/^[1-9][0-9]*$/.test(value) || value > 10 || value < 1) {
        e.target.elements.content.value = "";
        return;
      }
    }

    stack[0][1][stack[0][0]] = Number(value);
    e.target.elements.content.value = "";
    e.target.elements.content.placeholder = value;

    setData(JSON.parse(JSON.stringify(data)));
    save();
  }

  function addItem(original, e) {
    e.preventDefault();
    if (e.target.elements.content.value.trim().length != 0)
      original.push([
        e.target.elements.option.value,
        e.target.elements.content.value,
      ]);
    e.target.elements.content.value = "";
    setData(JSON.parse(JSON.stringify(data)));
    save();
  }

  function toggle(data, key) {
    data[key] = !data[key];
    setData(JSON.parse(JSON.stringify(data)));
    save();
  }

  function optionChange(data, key) {
    data.toggle.selected = key;
    setData(JSON.parse(JSON.stringify(data)));
    save();
  }

  function save() {
    //e.target.disabled = true;
    updateConfig({ variables: { uid: uid, content: data } })
  }

  let enabled = (data) => {
    if (!data.hasOwnProperty("enabled") && !data.hasOwnProperty("toggle")) return;
    if (data.toggle) {
      return (
        <Form onChange={(e)=>optionChange(data, e.target.selectedIndex)} className={`${
          data.toggle.options[data.toggle.selected][0] === 'disabled' ?
          'bg-danger' : 'bg-primary'
        } text-light pr-1`} dir="rtl" style={{fontSize: '14px'}} as="select">
          {data.toggle.options.map((option, i) => <option id={i}  selected={data.toggle.selected == i}>{option[1]}</option>)}
        </Form>
      )
    } else if (data.enabled) {
      return (
        <Button
          onClick={() => toggle(data, "enabled")}
          size="sm border-right-0 border-bottom-0 border-top-0 border-left"
        >
          활성
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => toggle(data, "enabled")}
          size="sm bg-danger border-right-0 border-bottom-0 border-top-0 border-left"
        >
          비활성
        </Button>
      );
    }
  };

  let filterForm = (data, stack) => (
    <Form
      onSubmit={
        Array.isArray(data) ? (e) => addItem(data, e) : (e) => modify(stack, e, data)
      }
    >
      <InputGroup size="sm">
        {(() => {
          if (Array.isArray(data))
            return (
              <InputGroup.Prepend
                variant="bg-secondary"
                title="선택"
                id="input-group-dropdown-1"
              >
                <select
                  name="option"
                  style={{backgroundColor: getCardByStack(stack)}}
                  className="text-light border-0 rounded"
                  id="inputGroupSelect01"
                >
                  <option value="string">문자열</option>
                  <option value="regex">정규식</option>
                </select>
              </InputGroup.Prepend>
            );
        })()}
        <FormControl
          name="content"
          placeholder={Array.isArray(data) ? "" : data}
          aria-describedby="basic-addon1"
        />
        <InputGroup.Append>
          <Button
            style={{backgroundColor: getCardByStack(stack)}}
            variant={"outline-secondary text-light"}
            type="submit"
          >
            {Array.isArray(data) ? "추가" : "수정"}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );

  let collapse = (data, stack, i) => {
    if (Array.isArray(data)) {
      return (
        <div>
          {filterForm(data, stack)}
          <div style={{backgroundColor:getCardByStack(stack, 1)}} className="p-1 rounded">
            {data.map((item, i) => {
              if (!Array.isArray(item))
                return <div>{JSON.stringify(item)}</div>;
              return (
                <div key={i}>
                  <div className="d-flex">
                    <div className="border-right pl-1 pr-2 mr-2">
                      {dictionary[item[0]] || item[0]}
                    </div>
                    <div className="flex-grow-1">{item[1]}</div>

                    <Button className="bg-danger border-0"
                    onClick={() => deleteItem(data, i)}
                      style={{
                        width: "24px",
                        height: "24px",
                        paddingTop: "0px",
                        paddingLeft: "8px",
                      }}
                    >
                      ×
                    </Button>
                  </div>
                  {data.length >= i + 2 && (
                    <hr
                      style={{ borderTop: "1px solid white" }}
                      className="m-0"
                    ></hr>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (typeof data !== "object" || !data) {
      return <>{filterForm(data, stack)}</>;
    }
    return (
      <Accordion.Collapse eventKey={i}>{nest(data, stack, i)}</Accordion.Collapse>
    );
  };

  let getCardByStack = (s, offset) => {
    let stack = s.length - 1 + (offset || 0);
    if (stack == 0) return "#111";
    if (stack == 1) return "#333";
    if (stack % 2 == 0) return "#555";
    if (stack % 2 == 1) return "#777";
  };

  let nestedCard = (data, stack, i) => {
    return (
      <Card
        style={{backgroundColor: getCardByStack(stack)}}
        className="text-light border-0 pb-1 border-primary"
        key={i}
      >
        <div className="d-flex m-0 p-0">
          <Accordion.Toggle
            style={{backgroundColor: getCardByStack(stack)}}
            className={"flex-grow-1 pb-0 pt-1 pl-2"}
            as={Card.Header}
            eventKey={i}
          >
            <>
              {} {`${dictionary[stack[0][0]] || stack[0][0]} ${Object.keys(data).length > 1 ? '▾' : ''}`}
            </>
          </Accordion.Toggle>
          {enabled(data)}
        </div>
        <div className="ml-2">{collapse(data, stack, i)}</div>
      </Card>
    );
  };

  let nest = (data, stack, i) => {
    return (
      <Accordion defaultActiveKey={1} className="border-0">
        {Object.keys(data).map((item) => {
          if (item == "enabled" || item == "toggle") return;
          stack.unshift([item, data]);
          let ret = nestedCard(data[item], stack.slice(), ++i);
          stack.shift();
          return ret;
        })}
      </Accordion>
    );
  };

  return <div className="mt-2">{nest(data, [], 0)}</div>;
};
export default withRouter(ServiceContainer);

/*
<Button
  className="bg-danger border-0 mt-1 mb-1 w-100"
  onClick={(e) => save(e)}
>
  저장
</Button>
*/
