import React, { Component } from "react";
import "./RoomList.css";
import { BILLING_INFO } from "../queries";
import { Query } from "react-apollo";
import LoadItem from "./LoadItem";

class RoomList extends Component {
  render() {
    return (
      <Query query={BILLING_INFO}>
        {({ loading, data, error }) => {
          if (loading) return <LoadItem />;
          if (error) return <span>oops! something is wrong...</span>;
          if (!data.getBillingInfo) return <span></span>;
          data = data.getBillingInfo;
          if (data.free)
            return (
              <div className="bg-light d-flex justify-content-between p-2 mt-2">
                <h5 className="m-0">
                  <strong>{`총 관리 인원수: ${data.mcnt}명`}</strong>
                </h5>
                <h5 className="m-0">무료 계정</h5>
              </div>
            );
          if (data.freeDate > new Date().getTime())
            return (
              <div className="bg-light p-2 mt-2">
                <h5 className="m-0">1일 무료 체험 기간</h5>
                <h5 className="m-0">{`만료 시간: ${new Date(
                  Number(data.freeDate)
                ).getDate()}일 ${new Date(
                  Number(data.freeDate)
                ).getHours()}시 ${new Date(
                  Number(data.freeDate)
                ).getMinutes()}분`}</h5>
              </div>
            );
          return (
            <div className="bg-light d-flex justify-content-between p-2 mt-2">
              <div>
                <h5 className="m-0">
                  <strong>{`납부 여부: ${
                    data.payPrice == 0 && data.isPaid ? "완납" : "미납"
                  }`}</strong>
                </h5>

                <p className="m-0">
                  {`정산일: ${data.calcDate[0]}월 ${data.calcDate[1]}일`}
                </p>
                <p className="m-0">
                  {`납부 만기일: ${data.payDate[0]}월 ${data.payDate[1]}일`}
                </p>

                <br></br>
                <h5 className="m-0">
                  <strong>{`총 관리 인원수: ${data.mcnt}명`}</strong>
                </h5>

                <a href={data.link} className="m-0 mb-1">
                  {data.link}
                </a>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
/*
                
                <h5 className="m-0">
                  <strong>{`납부 요금: ${data.payPrice == 0 ? '미정산' : `${data.payPrice}원`}`}</strong>
                </h5>
                                <h5 className="m-0">
                  <strong>{`예상 요금: ${Math.max(
                    data.mcnt * 150 + 50000
                  )}원`}</strong>
                </h5>
                */

export default RoomList;
