import React, { Component } from "react";
import Header from "../containers/Header";
import RoomList from "../containers/RoomList";

class RoomListHome extends Component {
  render() {
    return (
      <>
        <Header />
        <RoomList />
      </>
    );
  }
}

export default RoomListHome;
