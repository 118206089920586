import React from "react";
import "./RoomList.css";
import { SERVICE_LIST, ROOM_INFO, GET_CONFIG } from "../queries";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import ServiceContainer from "./ServiceContainer";
import ConfigItem from "./ConfigItem";

const ServiceList = ({
  match: {
    params: { uid },
  },
}) => {
  return (
    <div className='mt-2'>
      <Query fetchPolicy="no-cache" query={GET_CONFIG} variables={{ uid }}>
          {({ loading, data, error, refetch }) => {
            if (loading) return <span></span>;
            if (error) return <span>오류 발생</span>;
            if (!data.getRoomList) return <span></span>;
            
            return (
              <>
                <ConfigItem refetch={refetch} data={{uid: data.getRoomList[0].uid, name: data.getRoomList[0].name, hideSetting: true }}></ConfigItem>
                <ServiceContainer data={data.getRoomList[0].config}></ServiceContainer>
              </>
            )
          }}
      </Query>
    </div>

  );
};
export default withRouter(ServiceList);
