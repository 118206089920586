import gql from "graphql-tag";
import { GraphQLJSON } from 'graphql-type-json';

export const HOME_ROOM_LIST = gql`
  {
    getRoomList {
      uid
      name
      roomList {
        link
        linkId
        name
      }
    }
  }
`;

export const GET_CONFIG = gql`
  query getRoomList($uid: String!) {
    getRoomList(uid: $uid) {
      uid
      name
      config
    }
  }
`;

export const ROOM_LIST = gql`
  {
    getRooms {
      room {
        name
        link
      }
      payDate
      default {
        name
        link
      }
      useDefault
      remote
    }
  }
`;

export const ROOM_INFO = gql`
  query getRoomInfo($link: String!) {
    getRoomInfo(link: $link)
  }
`;

export const ADD_NEW_ROOM = gql`
  mutation addNewRoom($url: String!) {
    addNewRoom(url: $url) {
      success
      status
      message
    }
  }
`;

export const ADD_CONFIG = gql`
  mutation addNewConfig($name: String!) {
    addNewConfig(name: $name) {
      success
      status
      message
    }
  }
`;

export const REMOVE_CONFIG = gql`
  mutation removeConfig($uid: String!) {
    removeConfig(uid: $uid) {
      success
      status
      message
    }
  }
`;

export const CHANGE_CONFIG_NAME = gql`
  mutation changeConfigName($uid: String!, $name: String!) {
    changeConfigName(uid: $uid, name: $name) {
      success
      status
      message
    }
  }
`;

export const SERVICE_LIST = gql`
  query getServices($link: String!) {
    getServices(link: $link)
  }
`;

export const UPDATE_CONFIG = gql`
  mutation updateConfig($uid: String!, $content: JSON!) {
    updateConfig(uid: $uid, content: $content)
  }
`;

export const LOGIN = gql`
  mutation login($id: String!, $pw: String!) {
    login(id: $id, pw: $pw) {
      token
      id
      permission
    }
  }
`;

export const SIGN_UP = gql`
  mutation register($id: String!, $pw: String!) {
    register(id: $id, pw: $pw) {
      success
      status
      message
    }
  }
`;

export const USE_DEFAULT = gql`
  mutation useDefault($use: Boolean!) {
    useDefault(use: $use)
  }
`;

export const JOIN_LINK = gql`
  mutation joinLink($url: String!, $pw: String) {
    joinLink(url: $url, pw: $pw) {
      success
      status
      message
    }
  }
`;

export const LINK_ACTION = gql`
  mutation linkAction($link: String!, $action: String!, $content: JSON) {
    linkAction(link: $link, action: $action, content: $content) {
      success
      status
      message
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const RESTART = gql`
  mutation restart {
    restart
  }
`;

export const BILLING_INFO = gql`
  {
    getBillingInfo
  }
`;

export const IS_LOGIN = gql`
  {
    isLogin {
      token
      id
      permission
    }
  }
`;
