import React, { useState, createContext, useEffect } from 'react';
import { useMutation } from "react-apollo";
import "./RoomList.css";
import ConfigItem from "./ConfigItem";
import { HOME_ROOM_LIST } from "../queries";
import { Query } from "react-apollo";
import LoadItem from "./LoadItem";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { JOIN_LINK, ADD_CONFIG, ADD_NEW_ROOM, RESTART } from "../queries";
import RoomItem from './RoomItem';
import { tokenManager } from '../apolloClient';


const RoomList = () => {

  const [roomLink, setRoomLink] = useState('');
  const [roomPwd, setRoomPwd] = useState('');
  const [joinLink] = useMutation(JOIN_LINK);
  const [addConfig] = useMutation(ADD_CONFIG);
  const [addNewRoom] = useMutation(ADD_NEW_ROOM);
  const [restart] = useMutation(RESTART);

  const onChangeRoomLink = (event) => {
    setRoomLink(event.target.value)
  }

  const onChangeRoomPwd = (event) => {
    setRoomPwd(event.target.value)
  }
          
  const onSubmit = async (refetch) => {
    if (!roomLink.trim()) return;
    try {
      let res = await joinLink({
        variables: {
          url: roomLink,
          pw: roomPwd,
        },
      });
      if (!res.data.joinLink.success) {
        alert(`추가 실패!\n${res.data.joinLink.message}`);
      } else refetch();
    } catch (e) {
      console.log(e)
      alert("추가 실패!\n알 수 없는 오류 발생");
    }
  }

  
  const addProfile = async (refetch) => {
    let name = prompt("프로필의 이름을 입력해 주세요.");
    name = name && name.trim();
    if (name) {
      let res = await addConfig({ variables: { name } })
      if (!res.data.addNewConfig.success) {
        alert(`오류 발생!\n${res.data.addNewConfig.message}`);
      } else refetch();
    }
  }

  const onRestart = async () => {
    restart();
    alert('재시작 요청 완료');
  }

  const addRoom = async (refetch) => {
    let url = prompt("방 링크를 입력해 주세요.");
    url = url && url.trim();
    if (url) {
      let res = await addNewRoom({ variables: { url } })
      if (!res.data.addNewRoom.success) {
        alert(`오류 발생!\n${res.data.addNewRoom.message}`);
      } else refetch();
    }
  }

  return (
    <Query query={HOME_ROOM_LIST}>
      {({ loading, data, error, refetch }) => {
        if (loading) return <LoadItem />;
        if (error) return <span>error</span>;
        if (!data.getRoomList) return <span></span>;

        const roomGroupList = (refetch) => {
          let groupList = data.getRoomList.map(roomGroup=>({name: roomGroup.name, uid: roomGroup.uid}));
          return data.getRoomList.map((roomGroup, i) => {
            return <div className='p-2 mt-2' style={{backgroundColor: '#bbb'}}>
              <ConfigItem refetch={refetch} key={i} data={{uid: roomGroup.uid, name: roomGroup.name}}></ConfigItem>
              {roomGroup.roomList.map(room => <RoomItem refetch={refetch} data={{groupList, roomGroup, room}}/>)}
            </div>;
          });
        }

        const remotePanel = () => !tokenManager.permission.remotePermission ? <></> : <div className="bg-light d-flex justify-content-between p-2 mt-2">
            <div className="flex-grow-1 mr-1">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>원격 입장</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder='링크'
                value={roomLink}
                onChange={(e)=>onChangeRoomLink(e)}
              />
              <FormControl
                placeholder='비밀번호'
                value={roomPwd}
                onChange={(e)=>onChangeRoomPwd(e)}
              />
            </InputGroup>
          </div>
          <Button onClick={()=>onSubmit(refetch)} style={{height: '38px', fontSize: '30px', lineHeight:'26px'}}>+</Button>
        </div>

        const addProfileButton = () => {
          return <div className="d-flex justify-content-between">
            {tokenManager.permission.remotePermission && <Button variant='primary' className="flex-shrink-1 rounded-0 mt-2 mr-2" onClick={()=>onRestart()}>재시작</Button>}
            <Button variant='primary' className="flex-shrink-1 rounded-0 mt-2 mr-2" onClick={()=>addRoom(refetch)}>+ 방 추가</Button>
            <Button variant='primary' className="flex-grow-1 rounded-0 mt-2" onClick={()=>addProfile(refetch)}>+ 프로필 추가</Button>
          </div>
        }

        return (
          <>
            {remotePanel()}
            {addProfileButton()}
            {roomGroupList(refetch)}
          </>
        );
      }}
    </Query>
  );
}

export default RoomList;
