import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Header from '../containers/Header'
import ServiceList from '../containers/ServiceList'
import { withRouter } from 'react-router'

class RoomManager extends Component {
  render() {
    return (
        <>
            <Header/>
            <ServiceList/>
        </>
    )
  }
}

export default withRouter(RoomManager);
