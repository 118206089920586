import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './Auth.css';
import Login from './Login';
import Signup from './Signup';

const Auth = ({refetch}) => {
  return (
      <Router>
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Switch>
              <Route path="/auth/login" refetch component={()=><Login refetch={refetch}/>} />
              <Route path="/auth/signup" component={Signup} />
            </Switch>
          </div>
        </div>
      </Router>
  )
}

export default Auth;
