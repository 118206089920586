import React, { Component } from 'react';
import './Header.css';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LOGOUT } from '../queries';
import { useMutation, useQuery } from '@apollo/react-hooks';

const Header = () => {
  const [logout] = useMutation(LOGOUT);
  return (
    <Navbar bg="dark" variant="dark">
      <Link to='/home'><Navbar.Brand>싹둑봇</Navbar.Brand></Link>
      <Nav className="mr-auto">
        <Link to='/home'><Nav.Link>관리</Nav.Link></Link>
        <Nav.Link href="https://open.kakao.com/o/shgAeRcc" target="_blank">문의</Nav.Link>
      </Nav>
      <Nav>
        <Nav.Link onClick={async ()=>{
          await logout();
          document.location.reload();
        }}>로그아웃</Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default Header;
