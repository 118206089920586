import React, { useContext } from "react";
import { Badge, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ConfigItem.css";
import { GearFill, ThreeDots } from 'react-bootstrap-icons';
import { useMutation } from "react-apollo";
import { CHANGE_CONFIG_NAME, REMOVE_CONFIG, LINK_ACTION } from "../queries";

const actionEnum = Object.freeze({
  "changeConfigName": 1,
  "removeConfig": 2,
})


const ConfigItem = ({ refetch, data }) => {

  const [changeConfigName] = useMutation(CHANGE_CONFIG_NAME);
  const [removeConfig] = useMutation(REMOVE_CONFIG);

  const onClick = async (action) => {
    let res;
    switch (action) {

      case actionEnum.changeConfigName:
        let name = prompt("변경할 이름을 입력해 주세요.");
        name = name && name.trim();
        if (!name) return;
        res = await changeConfigName({ variables: { uid: data.uid, name: name } });
        if (!res.data.changeConfigName.success) {
          alert(`변경 실패\n${res.data.changeConfigName.message}`);
        } else refetch();
        break;

      case actionEnum.removeConfig:
        if (!window.confirm('[프로필 삭제]\n정말 삭제하시겠습니까?\n기존 프로필이 적용된 방은 기본 프로필로 전환됩니다.')) return;
        res = await removeConfig({ variables: { uid: data.uid } })
        if (!res.data.removeConfig.success) {
          alert(`삭제 실패\n${res.data.removeConfig.message}`);
        } else refetch();
        break;

      default:
        break;
    }
  }

  const menuDropdown = () => (<Dropdown className="">
    <Dropdown.Toggle className="no-caret rounded-0 inbtn" variant="secondary" id="dropdown-basic">
      <ThreeDots/>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={()=>onClick(actionEnum.changeConfigName)}>이름 변경</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item className="text-danger" onClick={()=>onClick(actionEnum.removeConfig)}>삭제</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>)

  return (
    <>
      <div className="bg-light d-flex justify-content-between" style={{height: '50px'}}>
        <div className='align-self-center' style={{overflow: 'hidden'}}>
          <h4 className="m-0 ml-3">
            <Badge variant="primary">프로필</Badge>
            <strong style={{'fontSize': '21px'}}>&nbsp;{data.name}</strong>
          </h4>
        </div>
        <div className="d-flex justify-content-between" >
          {data.hideSetting ? <></> : <Link to={`/chat/${data.uid}`}><Button className="rounded-0 inbtn"><GearFill/></Button></Link>}
          {data.uid !== 'default' ? menuDropdown() : <></>}
        </div>
      </div>
    </>
  );
};

export default ConfigItem;

/*
          <div>
              <Button className='btn-secondary btn-sm disabled'>광고차단</Button>
          </div>
*/
