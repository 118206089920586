import React, { Component } from 'react';
import Header from '../containers/Header'
import Dashboard from '../containers/Dashboard'

class RoomDashboardHome extends Component {
  render() {
    return (
        <>
            <Header/>
            <Dashboard/>
        </>
    )
  }
}

export default RoomDashboardHome;
