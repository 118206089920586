import React from "react";
import Header from "../containers/Header";
import { Query } from "react-apollo";
import { LOGOUT } from "../queries";
import LoadItem from "../containers/LoadItem";

const Logout = () => {
  return (
    <>
      <Header />
      <Query query={LOGOUT}>
        {({ loading, data, error }) => {
          if (loading) return <LoadItem />;
          if (error) return <span>oops! something is wrong...</span>;
          window.location.href = "/auth/login"
          return <></>;
        }}
      </Query>
    </>
  );
};

export default Logout;
