import React, { useContext, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ConfigItem.css";
import { GearFill, ThreeDots } from 'react-bootstrap-icons';
import { useMutation } from "react-apollo";
import { LINK_ACTION } from "../queries";

const actionEnum = Object.freeze({
  "removeFromList": 1,
  "leaveAndRemoveFromList": 2,
  "changeConfig": 3
})


const RoomItem = ({ data, refetch }) => {

  const [linkAction] = useMutation(LINK_ACTION);

  const [dropdownState, setDropdownState] = useState('default')

  const onClick = async (action, content) => {
    let res;
    switch (action) {

      case actionEnum.removeFromList:
        if (!window.confirm('[목록에서 삭제]\n정말 삭제하시겠습니까?')) return;
        res = await linkAction({ variables: { link: data.room.link, action: 'removeFromList' } })
        if (!res.data.linkAction.success) {
          alert(`삭제 실패\n${res.data.linkAction.message}`);
        } else refetch();
        break;

      case actionEnum.leaveAndRemoveFromList:
        if (!window.confirm('[퇴장 후 삭제]\n정말 삭제하시겠습니까?')) return;
        res = await linkAction({ variables: { link: data.room.link, action: 'leaveAndRemoveFromList' } })
        if (!res.data.linkAction.success) {
          alert(`삭제 실패\n${res.data.linkAction.message}`);
        } else refetch();
        break;

      case actionEnum.changeConfig:
        res = await linkAction({ variables: { link: data.room.link, action: 'changeConfig', content: { uid: content } } })
        if (!res.data.linkAction.success) {
          alert(`오류 발생!\n${res.data.linkAction.message}`);
        } else refetch();
        break;

      default:
        break;
    }
  }

  const menuDropdown = () => {
    if (dropdownState === 'default') {
      return (<Dropdown className="">
        <Dropdown.Toggle className="no-caret rounded-0 inbtn" variant="secondary" id="dropdown-basic">
          <ThreeDots/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>onClick(setDropdownState('room'))}>프로필 변경</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item className="text-danger" onClick={()=>onClick(actionEnum.removeFromList)}>목록에서 삭제</Dropdown.Item>
          <Dropdown.Item className="text-danger" onClick={()=>onClick(actionEnum.leaveAndRemoveFromList)}>퇴장 후 삭제</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>)
    } else {
      return (<Dropdown show={dropdownState==='room'} onToggle={()=>setDropdownState('default')}>
        <Dropdown.Toggle className="no-caret rounded-0 inbtn" variant="secondary" id="dropdown-basic">
          <ThreeDots/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>프로필 변경</Dropdown.Header>
          {data.groupList.map(group=> group.uid === data.roomGroup.uid ? <></> : <Dropdown.Item key={group.uid} onClick={()=>onClick(actionEnum.changeConfig, group.uid)}>{group.name}</Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>)
    }
  }

  return (
    <>
      <div className="bg-light d-flex justify-content-between mt-2" style={{height: '50px'}}>
        <div className='align-self-center ml-3' style={{overflow: 'hidden'}}>
          <h5 className='room-item-name'>
            <strong>{data.room.name}</strong>
          </h5>
          <a href={data.room.link} target="_blank">
            <h6 style={{'fontSize': '14px', marginTop: '-2px', textOverflow: 'ellipsis', overflow: 'hidden'}}>
              {data.room.link.substring(8)}
            </h6>
          </a>
        </div>
        <div className="d-flex justify-content-between">
            {menuDropdown()}
        </div>
      </div>
    </>
  );
};

export default RoomItem;

/*
          <div>
              <Button className='btn-secondary btn-sm disabled'>광고차단</Button>
          </div>
*/
