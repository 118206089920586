import React, { Component, useEffect } from "react";
import "./App.css";
import { IS_LOGIN } from "./queries";
import "bootstrap/dist/css/bootstrap.min.css";
import Auth from "./containers/Auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import RoomListHome from "./pages/RoomListHome";
import RoomManager from "./pages/RoomManager";
import apolloClient, { tokenManager } from "./apolloClient";
import { ApolloProvider, Query } from "react-apollo";
import RoomDashboardHome from "./pages/RoomDashboardHome";
import Logout from "./pages/Logout";
import Login from "./containers/Login";

const App = () => {
  
  let refetchSchedule;
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (refetchSchedule) refetchSchedule();
    }, 60 * 1000 );

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <div className="container p-0">
          <Query query={IS_LOGIN}>
            {({ loading, data, error, refetch }) => {
              refetchSchedule = refetch;
              if (loading) return <span></span>;
              if (error) return <span>error</span>;
              if (!data.isLogin){
                return (
                  <Switch>
                    <Route path="/auth/login" exact={true} component={()=><Auth refetch={refetch}/>} />
                    <Route path="/auth/signup" exact={true} component={Auth} />
                    <Redirect to="/auth/login" />
                  </Switch>
                );
              } else {
                if (data.isLogin.token) tokenManager.token = data.isLogin.token;
                if (data.isLogin.permission) tokenManager.permission = data.isLogin.permission;
              }
              return (
                <Switch>
                  <Route path="/home" exact={true} component={RoomListHome} />
                  <Route path="/chat/:uid" component={RoomManager} />
                  <Route
                    path="/dashboard"
                    exact={true}
                    component={RoomDashboardHome}
                  />
                  <Route path="/logout" exact={true} component={Logout} />
                  <Redirect to="/home" />
                </Switch>
              );
            }}
          </Query>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;

//
//
