import React from "react";

const LoadItem = () => {
  return (
    <>
      <div className="bg-light d-flex justify-content-center p-2 mt-2">
          <div className="spinner-border text-primary"></div>
      </div>
    </>
  );
};

export default LoadItem;

/*
          <div>
              <Button className='btn-secondary btn-sm disabled'>광고차단</Button>
          </div>
*/
